// @flow
import * as React from 'react';
import WelcomeBanner from './WelcomeBanner';
import SingleColumnRow from './PageContent/SingleColumnRow';
import ClientLogos from './PageContent/ClientLogos';
import ContactFormRow from '../containers/PageContent/ContactFormRow';
import FeaturedResources from './PageContent/FeaturedResources';
import FeaturedProducts from './PageContent/FeaturedProducts';
import ProductRow from './PageContent/ProductRow';
import SuiteRow from './PageContent/SuiteRow';
import IntroCopy from './PageContent/IntroCopy';
import IndustryCallouts from './PageContent/IndustryCallouts';
import ExtendedContactInformation from './PageContent/ExtendedContactInformation';
import SocialMediaRow from '../containers/PageContent/SocialMediaRow';
import VideoRow from './PageContent/VideoRow';
import LeadRow from './PageContent/LeadRow';
import FeatureGrid from './PageContent/FeatureGrid';
import IconSteps from './PageContent/IconSteps';
import SuiteProductRow from './PageContent/SuiteProductRow';
import InlineCallbackFormRow from '../containers/PageContent/InlineCallbackFormRow';
import IntroCopyWithCallbackForm from '../containers/PageContent/IntroCopyWithCallbackForm';
import FeatureScreenshots from './PageContent/FeatureScreenshots';
import TwoColumn from './PageContent/TwoColumn';
import HelixPersonaList from './HelixPersonaList';
import GetStartedWithProductCTA from '../containers/PageContent/GetStartedWithProductCTA';
import ContactOptions from './PageContent/ContactOptions';
import ImageAndContent from './PageContent/ImageAndContent';
import InfoTab from './PageContent/InfoTab';
import VideoAndContent from './PageContent/VideoAndContent';
import CommunitySummaries from './CommunitySummeries';
import PersonaListingHeader from './PersonaListingHeader';

import type { PageContentArray } from '../types';

type Props = {
  pageContent: ?PageContentArray,
  extraClass?: string,
  persona_slug?: any,
};

const PageContent = ({ pageContent, extraClass, persona_slug }: Props) => {
  if (pageContent) {
    // $FlowFixMe
    return pageContent.map((content, i) => {
      switch (content.acfFcLayout) {
        case 'single_column_row':
          return <SingleColumnRow key={i} row={content} />;
        case 'client_logos':
          return <ClientLogos key={i} row={content} />;
        case 'contact_form_with_text':
          return <ContactFormRow key={i} row={content} />;
        case 'featured_resources':
          return <FeaturedResources key={i} row={content} />;
        case 'featured_products':
          return <FeaturedProducts key={i} row={content} />;
        case 'suite_row':
          return <SuiteRow key={i} row={content} />;
        case 'product_row':
          return <ProductRow key={i} row={content} />;
        case 'intro_copy':
          return <IntroCopy key={i} row={content} extraClass={extraClass} />;
        case 'industry_callouts':
          return <IndustryCallouts key={i} row={content} />;
        case 'video_row':
          //  $FlowFixMe
          return <VideoRow key={i} row={content} />;
        case 'extended_contact_information':
          return <ExtendedContactInformation key={i} row={content} />;
        case 'social_media_row_with_title':
          return <SocialMediaRow key={i} row={content} />;
        case 'lead_row':
          return <LeadRow key={i} row={content} />;
        case 'feature_grid':
          return <FeatureGrid key={i} row={content} />;
        case 'icon_steps':
          return <IconSteps key={i} row={content} />;
        case 'suite_product_row':
          return <SuiteProductRow key={i} row={content} />;
        case 'inline_callback_form':
          return <InlineCallbackFormRow key={i} row={content} />;
        case 'intro_copy_with_callback_form':
          return <IntroCopyWithCallbackForm key={i} row={content} />;
        case 'feature_screenshots':
          return <FeatureScreenshots key={i} row={content} />;
        case 'two_column':
          return <TwoColumn key={i} row={content} />;
        case 'get_started_with_product_cta':
          return <GetStartedWithProductCTA key={i} row={content} />;
        case 'image_and_content':
          return <ImageAndContent key={i} row={content} />;
        case 'info_tab':
          return <InfoTab key={i} row={content} />;
        case 'video_and_content':
          return <VideoAndContent key={i} row={content} />;
        case 'community_summaries':
          return <CommunitySummaries key={i} row={content} />;
        case 'welcome_banner':
          return <WelcomeBanner key={i} row={content} />;
        case 'contact_options':
          return <ContactOptions key={i} row={content} />;
        case 'helix_persona_list':
        case 'helix_persona_list_nz':
        case 'helix_persona_list_id':
          return (
            <HelixPersonaList
              key={i}
              row={content}
              persona_slug={persona_slug}
            />
          );
        case 'persona_listing_header':
          return <PersonaListingHeader key={i} row={content} />;
        default:
          return (
            <p key={i}>
              <strong>
                Error: Missing Case Statement in ACF Layout (
                {content.acfFcLayout})
              </strong>
            </p>
          );
      }
    });
  } else {
    return null;
  }
};

export default PageContent;
