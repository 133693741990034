// @flow
import * as React from 'react';
import Link  from '../Link';
import ReactHtmlParser from 'html-react-parser';
import type { Community as CommunityType } from '../../types';

type Props = {
  community: CommunityType,
  welcomePage: {
    acf: {
      title: string,
      browseCommunitySubtitle: string,
      browseCommunityText: string,
      buttonOne: {
        title: string,
        url: string,
        target: string,
      },
      buttonTwo: {
        title: string,
        url: string,
        target: string,
      },
    },
  },
};

const PersonaBanner = ({
  community,
  welcomePage,
}: Props): React.Element<'div'> => {

  console.log(community.acf)

  return (
    <div className="persona">
      <div className="persona__banner">
        {community?.acf?.communityImageGallery?.slice(0, 4).map((img, i) => (
          <div
            className={`banner__item banner__item--1-col banner__item--${i}`}
            key={i}
          >
            <img
              className="img-container__img"
              src={img.url}
              alt={img.alt}
              title={img.title}
            />
          </div>
        ))}
        <div
          className="banner__item banner__item--blank blank--1"
          style={{ backgroundColor: community.acf.color }}
        />
        <div
          className="banner__item banner__item--blank blank--2"
          style={{ backgroundColor: community.acf.color }}
        />
        <div className="banner__item banner__item--blank blank--3" />
      </div>
      <div className="persona-banner__content-wrapper">
        <div className="persona-banner__content">
          <h3 style={{ color: community.acf.color }}>
            {community.acf.personacommunityNumber} | {community.acf.title}
          </h3>
          <h1>{welcomePage?.acf?.title}</h1>
          {ReactHtmlParser(community.acf.listingSummary)}
          <div className="persona-banner__button-group">
            <Link
              url={welcomePage?.acf?.buttonOne?.url}
              className="button"
              target={welcomePage?.acf?.buttonOne?.target}
            >
              {welcomePage?.acf?.buttonOne?.title}
            </Link>
            <Link
              url={welcomePage?.acf?.buttonTwo?.url}
              className="button"
              target={welcomePage?.acf?.buttonTwo?.target}

            >
              {welcomePage?.acf?.buttonTwo?.title}
            </Link>

          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonaBanner;
