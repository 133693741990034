// @flow

import * as React from 'react';
import { Grid, Container } from 'semantic-ui-react';
import type { TwoColumn as ContentType } from '../../types';
import ReactHtmlParser from 'html-react-parser';

type Props = {
  row: ContentType
};

class TwoColumn extends React.Component<Props> {
  render() {
    const { row } = this.props;
    return (
      <Container className="two-column">
        <Grid>
          <Grid.Row
            className={`${row.pullLeftUnderRightOnMobile ? 'reversed' : ''}`}
          >
            <Grid.Column computer={6} tablet={6} mobile={12}>
              {row.leftColumn && (
                <div className="body--lead">
                  {ReactHtmlParser(row.leftColumn)}
                </div>
              )}
            </Grid.Column>
            <Grid.Column computer={6} tablet={6} mobile={12}>
              {row.rightColumn && (
                <div className="body--lead">
                  {ReactHtmlParser(row.rightColumn)}
                </div>
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    );
  }
}

export default TwoColumn;
