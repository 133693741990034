import React from 'react';
import { idByProduct } from '../helpers';
import { Route, Switch } from 'react-router-dom';
import { PrivateRoute, LogIn, LogOut, ForgotPassword } from 'roy-morgan-auth';
import Home from './Home';
import Community from './Community';
import Persona from './Persona';
import LandingPageOne from './LandingPages/LandingPageOne';
import LandingPageTwo from './LandingPages/LandingPageTwo';
import LandingPageThree from './LandingPages/LandingPageThree';
import PersonaListing from './PersonaListing';

class RootContent extends React.Component {
  render() {
    return (
      <div className="page-container">
        <Switch>
          <Route exact path="/" component={Home} />
          {/************ LANDING PAGES START  ************/}
          <Route
            path="/why-helix-personas-market-segmentation"
            component={LandingPageOne}
          />
          <Route
            path="/why-helix-personas-market-research"
            component={LandingPageTwo}
          />
          <Route
            path="/why-helix-personas-audience-targeting"
            component={LandingPageThree}
          />
          {/************ LANDING PAGES FINISH  ************/}
          <Route exact path="/personas" component={PersonaListing} />
          <Route path="/:community_slug/:persona_slug" component={Persona} />
          <Route path="/:community_slug" component={Community} />
          <Route path="/:persona_slug" component={Home} />
          <Route exact={true} path="/login" component={LogIn} />
          <Route exact={true} path="/logout" component={LogOut} />
          <PrivateRoute exact={true} path="/logout" component={LogOut} />
          <Route
            path="/forgot-password"
            render={(props) => (
              <ForgotPassword productFamilyId={idByProduct['Helix Personas']} />
            )}
          />
        </Switch>
      </div>
    );
  }
}

export default RootContent;
